import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/career";
import Invoices from "./scenes/emails";
import Blogs from "./scenes/blogs";
import Contacts from "./scenes/client-messages";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import NewBlog from "./scenes/blogs/NewBlog";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/blogs.css";
import "../src/styles/newBlog.css";
import NewOpenPosition from "./scenes/career/newRoleform";
import Login from "./scenes/login";
import ProtectedRoute from "./utils/ProtectedRoutes";
import Applications from "./scenes/job-applications";
import EditRole from "./scenes/career/Editrole";
import EditBlog from "./scenes/blogs/EditBlog";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  // console.log("route- ",location.pathname);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {location.pathname === "/" ? (
            ""
          ) : (
            <Sidebar isSidebar={isSidebar} />
          )}
          <main className="content">
            {location.pathname === "/" ? (
              ""
            ) : (
              <Topbar setIsSidebar={setIsSidebar} />
            )}

            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/careers" element={<Team />} />
                <Route path="/applications" element={<Applications />} />
                <Route path="/career/new" element={<NewOpenPosition />} />
                <Route path="/career/edit" element={<EditRole />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/subscription" element={<Invoices />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blog/new" element={<NewBlog />} />
                <Route path="/blog/edit" element={<EditBlog />} />
              </Route>
              <Route path="*" element={<h1>Page not found!!</h1>} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
