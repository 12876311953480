import { Box, Button, Select, TextField, MenuItem } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useState } from "react";
import axios from "axios";
import { BLOGS_URL } from "../../utils/apiRoutes";
import { useLocation } from "react-router-dom";

const EditBlog = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const location = useLocation();
  const blogData = location?.state?.data;
  
  const [textbox, setTextbox] = useState(blogData.content);

    const initialValues = {
      author: blogData?.author || "",
      avatar: blogData?.avatar || "",
      title: blogData?.title || "",
      date: blogData?.date || "",
      category: blogData?.category || "Category",
      thumbnail: blogData?.thumbnail || "",
      content: blogData?.content || "",
      description: blogData?.description || "",
    };

  const handleFormSubmit = async (values, actions) => {
    values = { ...values, content: textbox };
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    try {
      await axios.put(`${BLOGS_URL}/${blogData._id}`, values);
      alert("Blog updated");
    } catch (err) {
      alert("Something went wrong!!");
      console.log(err);
    }
  };

  return (
    <Box m="20px">
      <Header title="EDIT Blog" subtitle="Update the Blog " />

      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Author"
                sx={{ gridColumn: "span 2" }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.author}
                name="author"
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Avatar Link"
                sx={{ gridColumn: "span 2" }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.avatar}
                name="avatar"
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                sx={{ gridColumn: "span 2" }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.title}
                name="title"
              />

              <TextField
                fullWidth
                variant="filled"
                type="date"
                sx={{ gridColumn: "span 2" }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.date}
                name="date"
              />
              <Select
                sx={{ gridColumn: "span 2" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.values.category}
                name="category"
                label="Category"
                onChange={props.handleChange}
                style={{
                  borderBottom: "1px solid #f2f2f2",
                  background: "#323948",
                }}
              >
                <MenuItem value="Category">Category</MenuItem>
                <MenuItem value="Web Development">Web Development</MenuItem>
                <MenuItem value="Mobile Development">
                  Mobile Development
                </MenuItem>
                <MenuItem value="AI/ML">AI/ML</MenuItem>
              </Select>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Thumbnail Link"
                sx={{ gridColumn: "span 2" }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.thumbnail}
                name="thumbnail"
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                sx={{ gridColumn: "span 4" }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.description}
                name="description"
              />

              <ReactQuill
                sx={{ gridColumn: "span 4" }}
                theme="snow"
                modules={EditBlog.modules}
                formats={EditBlog.formats}
                value={textbox}
                name="content"
                onChange={setTextbox}
                style={{ width: "80vw", height: "180px", color: "white" }}
              />
            </Box>

            <Box display="flex" justifyContent="left" mt="70px">
              <Button type="submit" color="secondary" variant="contained">
                Update Blog
              </Button>
            </Box>
            {/* <button type="submit">Submit</button> */}
          </form>
        )}
      </Formik>
    </Box>
  );
};



export default EditBlog;
