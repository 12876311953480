import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockOpenRoles } from "../../data/mockData";
import Header from "../../components/Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import addIcon from "../../images/addIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { JOBS_URL } from "../../utils/apiRoutes";
import useFetch from "../../utils/CustomHook";


const Team = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "jobTitle",
      headerName: "Job Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "workType",
      headerName: "Work Type",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
    },
    {
      field: "skills",
      headerName: "Skills",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            <Link to="/career/edit" state={{data: params.row}}>
              <EditIcon
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  color: "#1974d2",
                }}
              />
            </Link>
            <DeleteIcon
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#f44336",
              }}
            />
          </>
        );
      },
    },
  ];

  const { isLoading, apiError, apiData } = useFetch("GET", JOBS_URL, {});

  if (apiError) return <h1>SOmething went wrong. <p>{apiError}</p></h1>;

  const jobs = apiData?.map((item, index) => ({ ...item, id: index + 1 }));

  return (
    <Box m="20px">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header title="Careers" subtitle="List of open positions" />
        <div className="blogs-addblogsDiv">
          <button
            className="blogs-addBtn"
            onClick={() => navigate("/career/new")}
          >
            <img src={addIcon} alt="add" className="blogs-addIcon" />
            <span>Add new role</span>
          </button>
        </div>
      </div>

      <Box
        m="10px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          <h5>Loading...</h5>
        ) : (
          <DataGrid
            rows={jobs}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Team;
