import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockOpenRoles } from "../../data/mockData";
import Header from "../../components/Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import addIcon from "../../images/addIcon.svg";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { mockApplications } from "../../data/mockData";
import useFetch from "../../utils/CustomHook";
import { APPLICATIONS_URL } from "../../utils/apiRoutes";
import moment from "moment/moment";


const Applications = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      cellClassName: "name-column--cell",
      // editable: true,
      renderCell: (params) => {
        return moment(params?.row?.date).format('DD/MM/YYYY')
      }
      // headerAlign: "center",
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
      editable: true,
      // headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone number",
      flex: 1,
    },
    {
      field: "designation",
      headerName: "Designation Applied",
      flex: 1,
    },
    {
      field: "resume",
      headerName: "Resume",
      renderCell: (params) => {
        return (
          <>
            {/* <a href={params.row.resume}>Resume</a> */}
            <DownloadIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(params.row.resume, "_blank", "noopener,noreferrer")
              }
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            {/* <EditIcon
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#1974d2",
              }}
            /> */}

            <DeleteIcon
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#f44336",
              }}
            />
          </>
        );
      },
    },
  ];

  const { isLoading, apiError, apiData } = useFetch("GET", APPLICATIONS_URL, {});

  if (apiError) return <h1>SOmething went wrong. <p>{apiError}</p></h1>;

  const applications = apiData?.map((item, index) => ({ ...item, id: index + 1 }));

  return (
    <Box m="20px">
      <Header title="Job-Applications" subtitle="List of all applications" />

      <Box
        m="10px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {
          isLoading ? (<h5>Loading...</h5>) : (
            <DataGrid
              rows={applications}
              columns={columns}
              components={{ Toolbar: GridToolbar }}

            />
          )
        }
      </Box>
    </Box>
  );
};

export default Applications;
