import React, { useState } from 'react'
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";

const Login = () => {
 
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
 
    const handleSubmit = (e) => {
      e.preventDefault();
      if (username === "admin" && password === "1234") {
        setCookie("user", username, { path: "/" });
        // localStorage.setItem("token", "success");
        navigate("/careers");
        // window.location.reload();
      }
      else {
        alert("Username or Password Incorrect !!");
      }
      
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (

    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100 text-black">
        <MDBCol col="12">
          <MDBCard
            className="bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <MDBCardBody className="p-5 w-100 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center mb-5">Admin Login</h2>

              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Username"
                id="formControlLg"
                type="text"
                size="lg"
                onChange={(e) => setUsername(e.target.value)}
              />
              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Password"
                id="formControlLg"
                type={passwordShown ? "text" : "password"}
                size="lg"
                onChange={(e) => setPassword(e.target.value)}
                className="passwordInput"
              />

              <MDBCheckbox
                name="flexCheck"
                id="flexCheckDefault"
                className="mb-4"
                label="See password"
                onClick={() => togglePasswordVisiblity()}
              />

              <MDBBtn size="lg" onClick={handleSubmit}>
                Login
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;