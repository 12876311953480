import React, { useState } from "react";
import {
  Box,
  Button,
  Select,
  TextField,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../components/Header";
import * as yup from "yup";
import ReactQuill from "react-quill";
import useFetch from "../../utils/CustomHook";
import { JOBS_URL } from "../../utils/apiRoutes";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";



const EditRole = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [posting, setPosting] = useState(false);
  const location = useLocation();
  const editData = location?.state?.data;
  
  const initialValues = {
    jobTitle:editData?.jobTitle || "",
    workType:editData?.workType || "Full-Time",
    department:editData?.department || "Tech",
    description:editData?.description || "",
    roles:editData?.roles || "",
    skills:editData?.skills || "",
  };

  const postData = async (values) => {
    try {
      await axios.put(`${JOBS_URL}/${editData._id}`, values);
      setPosting(false);
      alert("Job role Updated !!");
    } catch (err) {
      setPosting(false);
      alert("Something went wrong");
      console.log(err);
    }
  };

  const handleFormSubmit = (values, actions) => {
    actions.setSubmitting(false);
    setPosting(true);
    postData(values);
    // console.log(values)
  };

  return (
    <Box m="20px">
      <Header title="EDIT ROLE" subtitle="Update Role for careers " />

      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Job Title"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.jobTitle}
                name="jobTitle"
                error={!!props.touched.jobTitle && !!props.errors.jobTitle}
                helperText={props.touched.jobTitle && props.errors.jobTitle}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.description}
                name="description"
                error={
                  !!props.touched.description && !!props.errors.description
                }
                helperText={
                  props.touched.description && props.errors.description
                }
                sx={{ gridColumn: "span 2" }}
              />

              <Select
                sx={{ gridColumn: "span 2" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.values.department}
                label="Department"
                name="department"
                onChange={props.handleChange}
                style={{
                  borderBottom: "1px solid #f2f2f2",
                  background: "#323948",
                }}
              >
                <MenuItem value="Tech">Tech</MenuItem>
                <MenuItem value="Non-Tech">Non-Tech</MenuItem>
              </Select>

              <Select
                sx={{ gridColumn: "span 2" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.values.workType}
                label="Work Type"
                name="workType"
                onChange={props.handleChange}
                style={{
                  borderBottom: "1px solid #f2f2f2",
                  background: "#323948",
                }}
              >
                <MenuItem value="Full-Time">Full-Time</MenuItem>
                <MenuItem value="Part-Time">Part-Time</MenuItem>
                <MenuItem value="Internship">Internship</MenuItem>
              </Select>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Add Roles & Responsibility, Use '|' symbol in between multiple roles"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.roles}
                name="roles"
                error={!!props.touched.roles && !!props.errors.roles}
                helperText={props.touched.roles && props.errors.roles}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Add Skills Required, Use '|' symbol in between multiple skills"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                value={props.values.skills}
                name="skills"
                error={!!props.touched.skills && !!props.errors.skills}
                helperText={props.touched.skills && props.errors.skills}
                sx={{ gridColumn: "span 4" }}
              />

            </Box>

            <Box display="flex" justifyContent="left" mt="70px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={posting ? true : false}
              >
                Update New Role
                {/* {false ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Create New Blog"
                )} */}
              </Button>
            </Box>
            {/* <button type="submit">Submit</button> */}
          </form>
        )}
      </Formik>
    </Box>
  );
};


export default EditRole;
