import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataBlog } from "../../data/mockData";
import Header from "../../components/Header";
import addIcon from "../../images/addIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetch from "../../utils/CustomHook";
import { BLOGS_URL } from "../../utils/apiRoutes";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

const Blogs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "avatar",
      headerName: "Avatar",
      renderCell: (params) => {
        return (
          <>
            <img width="50px" height="30px" src={params.row.avatar} />
          </>
        );
      },
    },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {new Date(params.row.date).getDate()}{" "}
            {months[new Date(params.row.date).getMonth()]}{" "}
            {new Date(params.row.date).getFullYear()}
          </>
        );
      },
    },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <img width="50px" height="30px" src={params.row.thumbnail} />
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            <Link to="/blog/edit" state={{ data: params.row }}>
              <EditIcon
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  color: "#1974d2",
                }}
              />
            </Link>
            <DeleteIcon
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#f44336",
              }}
            />
          </>
        );
      },
    },
  ];

  const { isLoading, apiError, apiData } = useFetch("GET", BLOGS_URL, {});

  if (apiError) return <h1>SOmething went wrong. <p>{apiError}</p></h1>;

  const blogs = apiData?.map((item, index) => ({ ...item, id: index + 1 }));

  const blogNavigate = () => {
    navigate("/blog/new");
  };

  return (
    <Box m="20px">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header title="BLOGS" subtitle="List of All Blogs" />
        <div className="blogs-addblogsDiv">
          <button className="blogs-addBtn" onClick={() => blogNavigate()}>
            <img src={addIcon} alt="add" className="blogs-addIcon" />
            <span>Add Blogs</span>
          </button>
        </div>
      </div>
      <Box
        m="10px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {isLoading ? (<h5>Loading...</h5>) : (<DataGrid checkboxSelection rows={blogs} columns={columns} />)}
      </Box>
    </Box>
  );
};

export default Blogs;
