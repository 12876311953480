import React from "react";
import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  return cookies["user"] !== "admin" ? <Navigate to="/login" /> : <Outlet />;
};

export default ProtectedRoute;
