const API_HOST = "https://revamp-lemon.vercel.app";
// const API_HOST = "http://localhost:3000";

export const BLOGS_URL = `${API_HOST}/api/blogs`;

export const JOBS_URL = "https://revamp-lemon.vercel.app/api/jobs";

export const CLIENTS_MESSAGE_URL = `${API_HOST}/api/clients`;

export const APPLICATIONS_URL = `${API_HOST}/api/applications`;

export const deleteMessage = (id) => `${API_HOST}/api/clients?id=${id}`;