import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockClientMessages } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import useFetch from "../../utils/CustomHook";
import { CLIENTS_MESSAGE_URL, deleteMessage } from "../../utils/apiRoutes";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { isLoading, apiError, apiData } = useFetch("GET", CLIENTS_MESSAGE_URL, {});

  if (apiError) return <h1>SOmething went wrong. <p>{apiError}</p></h1>;

  const messages = apiData?.map((item, index) => ({ ...item, id: index + 1 }));

  const handleDelete = async (id) => {
    const res = await axios.delete(deleteMessage(id));
    const data = await res?.data;

    if (data.success) {
      alert("Client Message deleted!!");
    }
    else {
      alert("Something went wrong!!");
    }
  }

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "firstName",
      headerName: "First Name",
      cellClassName: "name-column--cell",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
    },
    {
      field: "budget",
      headerName: "Budget",
    },
    {
      field: "message",
      headerName: "Message",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params?.row?.message} placement="top-start" className="cursor-pointer">
              <div className="cursor-pointer">{params?.row?.message}</div>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            <DeleteIcon
              onClick={() => handleDelete(params.row._id)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#f44336",
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="CONTACTS" subtitle="List of messages from clients." />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {isLoading ? (
          <h5>Loading...</h5>
        ) : (
          <DataGrid
            rows={messages}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>
    </Box>
  );
};



export default Contacts;
